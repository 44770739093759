/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiSearch, FiAlignJustify } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

import getValidationErrors from '../../../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer, FormContainerSearch } from './styles';

import api from '../../../../services/api';

import LeftMenu from '../../../../components/LeftMenu';
import InputWithLabel from '../../../../components/InputWithLabel';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';

import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';


import Modal from 'react-modal';

Modal.setAppElement('#root'); 

// Estilos para o modal
const customStyles = {
  content: {
    backgroundColor: '#d0f0c0', // Verde claro
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '700px',
    padding: '20px',
    borderRadius: '10px',
  },
};

// Estilos para o container da listagem
const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.6rem;
  max-height: 400px; /* Define uma altura máxima para a rolagem */
  overflow-y: auto; /* Adiciona uma barra de rolagem vertical quando necessário */

  .header {
    font-weight: bold;
    font-size: 1.2em;
    text-align: left;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
  }

  .row {
    display: contents; /* Faz a linha inteira clicável */
    cursor: pointer; /* Adiciona um cursor de ponteiro para indicar que é clicável */
    transition: background-color 0.5s; /* Adiciona transição suave para o hover */
  }

  .row:hover {
    background-color: #a8d5ba; /* Cor de fundo mais escura ao passar o mouse */
  }

  .item {
    padding: 0.5rem 0;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
`;

// Estilos para o ícone de fechar
const CloseIcon = styled(FaTimes)`
  position: relative;
  top: 1px;
  left: 10px;
  cursor: pointer;
  font-size: 1.5em;
  color: #333;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative; /* Necessário para posicionar o ícone */
`;

const Title = styled.h2`
  margin: 0;
`;

// Estilos para o campo de busca
const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

interface JudicialImplantationFormData {
  attendance_date: string;
  folder: string;
  description: string;
  user_id: string;
}

interface Location {
  id: string;
  name: string;
}

interface Client {
  id: string;
  name: string;
  active: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
}

interface ClientProcessComplete {
  id: string;
  process_number: string;
  client: Client;
  folder: string;
}

const NewList: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [clientProcess, setClientProcess] = useState<ClientProcess>();

  const locationState: Location = location.state as Location;

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [allClientProcess, setAllClientProcess] = useState<ClientProcessComplete[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null); // Crie uma referência para o campo de busca

  // const selectedUsers: string[] = [];

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setSearchTerm('');
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      api.get('/client-process/all').then((response) => {
        setAllClientProcess(response.data);
      });
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (modalIsOpen) {
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus(); // Coloca o foco no campo de busca ao abrir o modal
        }
      }, 100); // Ajuste o tempo conforme necessário
    }
  }, [modalIsOpen]);

  // Chamado quando clica na pasta do modal de clientes
  const handleItemClick = (pasta: any) => {
    console.log(pasta);
    formRef.current?.setData({ folder: pasta });
    closeModal();
    handleFindByFolder();
  };

  const filteredData = allClientProcess.filter(item =>
    item.client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (locationState) {
      formRef.current?.setData({ name: locationState.name });
    }
  }, [locationState]);

  const handleSubmit = useCallback(
    async (data: JudicialImplantationFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          folder: Yup.string().required('Pasta obrigatória'),
          attendance_date: Yup.string().required(
            'Data do andamento obrigatória',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.user_id = user.id;

        const attendanceType = await api.get(
          '/attendance-types/name/Implantação Judicial',
        );

        console.log(attendanceType);
        console.log(clientProcess);

        if (clientProcess) {
          /*
          const processAttendance = await api.post('/process-attendance', {
            client_id: clientProcess?.client.id,
            attendance_type_id: attendanceType.data.id,
            client_process_id: clientProcess?.id,
            description: data.description,
            attendance_date: data.attendance_date,
          });
          */

          await api.post('/judicial-implantation-list', {
            client_id: clientProcess?.client.id,
            client_process_id: clientProcess?.id,
            deadline: data.attendance_date,
            done: false,
            description: data.description,
            user_id: data.user_id,
          });

          history.push('/judicial-implantation-list');

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Implantação Judicial criada',
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso carregar o processo do cliente',
            description: 'Digite a pasta e clique na lupa.',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao cadastrar implantação judicial.',
        });
      }
    },
    [addToast, clientProcess, history, user.id],
  );

  const handleFindByFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    if (folder) {
      api.get(`/client-process/folder/${folder}`).then((response) => {
        if (response.data) {
          setClientProcess(response.data);
        } else {
          setClientProcess(undefined);
          addToast({
            type: 'info',
            title: 'Pasta não encontrada',
            description: 'Não foi possível localizar a pasta informada.',
          });
        }
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Cadastro de Implantação Judicial</h1>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <ModalHeader>
              <Title>Listagem de Processos</Title>
              <CloseIcon onClick={closeModal} />
            </ModalHeader>

            <SearchInput
              ref={searchInputRef}
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />

            <ListContainer>
              <div className="header">Cliente</div>
              <div className="header">Processo</div>
              <div className="header">Pasta</div>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  item.client.active === 'S' && (
                    <div key={index} className="row" onClick={() => handleItemClick(item.folder)}>
                      <div className="item">{item.client.name}</div>
                      <div className="item">{item.process_number}</div>
                      <div className="item">{item.folder}</div>
                    </div>
                  )
                ))
              ) : (
                allClientProcess.map((item, index) => (
                  item.client.active === 'S' && (
                    <div key={index} className="row" onClick={() => handleItemClick(item.folder)}>
                      <div className="item">{item.client.name}</div>
                      <div className="item">{item.process_number}</div>
                      <div className="item">{item.folder}</div>
                    </div>
                  )
                ))
              )}
            </ListContainer>
            {/* <button onClick={closeModal}>Fechar</button> */}
          </Modal>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainerSearch>
              <InputWithLabel name="folder" label="Pasta" type="text" />
              <Button
                type="button"
                title="Dados do cliente"
                onClick={handleFindByFolder}
              >
                <FiSearch size={30} />
              </Button>
              <Button
                type="button"
                title="Clientes"
                onClick={openModal}
              >
                <FiAlignJustify size={30} />
              </Button>
              <label>
                {clientProcess && clientProcess?.client.name}{' '}
                {clientProcess && ' - Processo: '}
                {clientProcess && clientProcess.process_number}
              </label>
            </FormContainerSearch>

            <FormContainer>
              <InputWithLabel name="attendance_date" label="Prazo" type="date" />
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Histórico"
                rows={3}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default NewList;
