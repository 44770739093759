/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback } from 'react';

import { useHistory, Link, useLocation } from 'react-router-dom';

import { isBefore, parseISO, format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { FiEdit3, FiXCircle, FiCheckSquare, FiInfo } from 'react-icons/fi';
// import { FiEdit3, FiXCircle } from 'react-icons/fi';

import {
  Container,
  Content,
  ContentArea,
  Avatar,
  StyledTooltip,
  FlexHeaderTable,
  TableColumn,
  FlexRow,
  useStyles,
  UserGridContainer,
  UserGridItem,
  FormContainerSearch,
  ProfileImage,
  UserContainer,
} from './styles';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import LeftMenu from '../../components/LeftMenu';
import Button from '../../components/Button';
// import TextArea from '../../components/TextArea';
import advocacia from '../../assets/advocacia.png';

interface Client {
  id: string;
  name: string;
  cpf: string;
  password: string;
  active: string;
}

interface City {
  id: string;
  name: string;
  uf: string;
}

interface ClientComplete {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  city: City;
}

interface ClientProcess {
  id: string;
  process_number: string;
  folder: string;
  client: Client;
}

interface IntimationType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
}

interface Responsible {
  id: string;
  user: User;
}

interface AttendanceType {
  id: string;
  name: string;
}

interface Intimation {
  id: string;
  description: string;
  deadline: string;
  clientProcess: ClientProcess;
  created_at: Date;
  creationDate: string;
  formattedDeadline: string;
  expiredDeadline: boolean;
  intimationType: IntimationType;
  responsibles: Responsible[];
  attendanceType: AttendanceType;
  user: User;
  avatar_url: string;
  updateUser: User;
  avatar_update_user: string;
}

const Intimation: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [intimations, setIntimations] = useState<Intimation[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | ''>('');
  const classes = useStyles();
  const location = useLocation(); 
  const [highlightedRow, setHighlightedRow] = useState<string | null>(null);
  const [todos, setTodos] = useState<string>('nao');

  // Extrai o parâmetro client_id da URL
  const params = new URLSearchParams(location.search);
  const client_id = params.get('client_id');

  const handleImageClick = (id: string) => {
    setSelectedUserId(id); // Define o ID do usuário clicado
    setTodos('nao');
    //const filteredData = allClientProcess.filter(item => item.client.name.toLowerCase().includes(searchTerm.toLowerCase())
    console.log('user da imagem', id);
    console.log('user', user.id);
  };

  const handleTodosClick = (id: string) => {
    setSelectedUserId(''); // Define o ID do usuário clicado
    setTodos('sim');
    //const filteredData = allClientProcess.filter(item => item.client.name.toLowerCase().includes(searchTerm.toLowerCase())
    console.log('user da imagem', id);
    console.log('user', user.id);
  };


  useEffect(() => {
    if (todos === 'sim') {
      api.get('/intimation').then((response) => {
        const intimationFormatted = response.data.map((item: Intimation) => {
          const d = new Date(`${item.deadline}T00:00:00`);
          return {
            ...item,
            creationDate: formatDate(item.created_at),
            formattedDeadline: isValid(d)
              ? format(d, " dd'/'MM'/'yyyy", {
                  locale: ptBR,
                })
              : '',
            expiredDeadline: isBefore(parseISO(item.deadline), new Date()),
            avatar_url: item.user ? `https://app-gobarberamx.s3.amazonaws.com/${item.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            avatar_update_user: item.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${item.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
          };
        });
  
        setIntimations(intimationFormatted);
      });
    }
  },[todos]);


  /*
  selectedUserId recebe valor quando clica na imagem do usuário para filtrar
  */
  useEffect(() => {
    //if (selectedUserId && client_id === undefined) {
    if (selectedUserId) {
      api.get(`/intimation/user/${selectedUserId}`)
        .then((response) => {
          const intimationFormatted = response.data.map((item: Intimation) => {
            const d = new Date(`${item.deadline}T00:00:00`);
            return {
              ...item,
              creationDate: formatDate(item.created_at),
              formattedDeadline: isValid(d)
                ? format(d, " dd'/'MM'/'yyyy", {
                    locale: ptBR,
                  })
                : '',
              expiredDeadline: isBefore(parseISO(item.deadline), new Date()),
              avatar_url: item.user ? `https://app-gobarberamx.s3.amazonaws.com/${item.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
              avatar_update_user: item.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${item.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            };
          });
          setIntimations(intimationFormatted);
        })
        .catch(error => {
          console.error('Error fetching intimations:', error);
        });
    }
  }, [selectedUserId]);

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };


  /*
  Se o client_id estiver nulo é porque não veio através da tela de detalhes do cliente (updateclient).
  Se não tiver valor lista os prazos (intimations) do usuário logado (user)
  */
  useEffect(() => {
    if (client_id === null) {
      setSelectedUserId(user.id);
    }
    
  }, [user.id, client_id]);

  useEffect(() => {
    api.get('/users').then((response) => {
      console.log('users', response.data);
      const usersFormatted = response.data.map((item: User) => {
        return {
          ...item,
          avatar_url: item ? `https://app-gobarberamx.s3.amazonaws.com/${item.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
        };
      });

      setUsers(usersFormatted);
    });
  }, []);

  
  /*
  Traz todos os prazos (intimation), pois o client_id (que vem quando a essa tela chamada pela tela updateclient)
  está com valor
  */
  useEffect(() => {
    if (client_id) {
      api.get('/intimation').then((response) => {
        const intimationFormatted = response.data.map((item: Intimation) => {
          const d = new Date(`${item.deadline}T00:00:00`);
          return {
            ...item,
            creationDate: formatDate(item.created_at),
            formattedDeadline: isValid(d)
              ? format(d, " dd'/'MM'/'yyyy", {
                  locale: ptBR,
                })
              : '',
            expiredDeadline: isBefore(parseISO(item.deadline), new Date()),
            avatar_url: item.user ? `https://app-gobarberamx.s3.amazonaws.com/${item.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            avatar_update_user: item.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${item.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
          };
        });
  
        setIntimations(intimationFormatted);
      });

    }
    
  }, [client_id]);

  useEffect(() => {
    const filteredIntimations: Intimation[] = intimations.filter(intimation => {
      return intimation.clientProcess && intimation.clientProcess.client.id === client_id; // Substitua 'RECADOS' pelo nome que você quer buscar
    });

    
    if (filteredIntimations.length > 0) {
      const inti = filteredIntimations[0];
      
      setHighlightedRow(inti.clientProcess.client.name);

      // setTimeout(() => {
      //   setHighlightedRow(null);
      // }, 800);
    } else {
      console.log('No matching client found.');
    }
  },[client_id, intimations]);
  

  const handleCallNewIntimation = useCallback(() => {
    history.push('/new-intimation');
  }, [history]);

  const handleDeleteIntimation = useCallback(async (intimationId: string, itemUser: User) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      if (itemUser) {
        if (user.id !== itemUser.id) {
          alert('Você não pode excluir um lançamento feito por outro usuário');
        }
        else {
          await api.delete(`/intimation/${intimationId}`).then((response) => {
            setIntimations((item) =>
              item.filter((_item) => _item.id !== intimationId),
            );
          });
        }
      }
      else {
        await api.delete(`/intimation/${intimationId}`).then((response) => {
          setIntimations((item) =>
            item.filter((_item) => _item.id !== intimationId),
          );
        });
      }
    }
  }, [user.id]);

  const handleCallUpdateIntimation = useCallback(
    (intimation: Intimation) => {
      history.push('/update-intimation', {
        intimation,
      });
    },
    [history],
  );

  const handleCallFinishIntimation = useCallback(
    (intimation: Intimation) => {
      history.push('/finish-intimation-list-item', {
        intimation,
      });
    },
    [history],
  );

  const handleCallClientView = useCallback(
    async (client: Client) => {
      await api.get(`/clients/cpf/${client.cpf}`).then((response) => {
        const cl: ClientComplete = response.data;

        localStorage.setItem(
          '@Rastreio:selectedClient',
          JSON.stringify(cl),
        );
        history.push('/update-client', { selectedClient: cl, caller: 'intimation' });
      });
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Prazos</h1>

          <FormContainerSearch>
            <Button type="button" onClick={handleCallNewIntimation}>
              Novo
            </Button>

            <UserContainer>
              <label>Clique em um usuário para filtrar os prazos</label>
              <UserGridContainer>
                <UserGridItem key={999}>
                  <ProfileImage
                    src={advocacia}
                    alt='Todos'
                    title='Todos'
                    isSelected={todos === 'sim'}
                    onClick={() => handleTodosClick(user.id)}
                  />
                </UserGridItem>
                {users.map((user) => (
                  <UserGridItem key={user.id}>
                    <ProfileImage
                      src={user.avatar ? user.avatar_url : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'}
                      alt={user.name}
                      title={user.name}
                      isSelected={selectedUserId === user.id}
                      onClick={() => handleImageClick(user.id)}
                    />
                  </UserGridItem>
                ))}
              </UserGridContainer>
            </UserContainer>
            
          </FormContainerSearch>

          <FlexHeaderTable>
            <FlexRow>
              <TableColumn width="1.4" textAlign="center"><b>Usuário</b></TableColumn>
              <TableColumn width="4" textAlign="center"><b>Cliente</b></TableColumn>
              <TableColumn width="4" textAlign="center"><b>Tipo</b></TableColumn>
              <TableColumn width="2.5" textAlign="center"><b>Prazo</b></TableColumn>
              <TableColumn width="4.5" textAlign="center"><b>Descrição</b></TableColumn>
              <TableColumn width="2" textAlign="center"><b>Responsáveis</b></TableColumn>
              <TableColumn width="2.5" textAlign="center">&nbsp;</TableColumn>
            </FlexRow>
            {intimations.map((item) => (
              item.clientProcess.client.active === 'S' && (
                <FlexRow key={item.id}
                  style={{
                    border: highlightedRow === item.clientProcess.client.name ? '2px solid #00b35b' : 'none',
                    transition: 'border 0.3s ease',
                  }}
                >
                  <TableColumn width="1.4">
                    <StyledTooltip title="Criador" arrow classes={{ tooltip: classes.customTooltip }}>
                      <Avatar>
                        {item.user ? (
                          <img src={item.avatar_url} alt={item.user.name} title={item.user.name} />
                        ) : (
                          <img
                            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                            alt="avatar"
                          />
                        )}
                      </Avatar>
                    </StyledTooltip>

                    <StyledTooltip title="Alterador" arrow classes={{ tooltip: classes.customTooltip }}>
                      <Avatar>
                        {item.updateUser ? (
                          <img src={item.avatar_update_user} alt={item.updateUser.name} title={item.updateUser.name} />
                        ) : (
                          <img
                            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                            alt="avatar"
                          />
                        )}
                      </Avatar>
                    </StyledTooltip>


                    
                  </TableColumn>
                  <TableColumn width="4">
                    <div>
                      <Link
                          to="/intimation"
                          title="Clique para dados do cliente"
                          onClick={() => {
                            item && handleCallClientView(item.clientProcess.client);
                          }}
                        >
                          <StyledTooltip title={item.clientProcess.client.name ? item.clientProcess.client.name : ''} arrow
                            classes={{ tooltip: classes.customTooltip }}
                          >
                            <strong>{item.clientProcess.client.name}</strong>
                          </StyledTooltip>
                          
                      </Link>
                    </div>
                  </TableColumn>
                  <TableColumn width="4">
                    <div>
                      <StyledTooltip title={item.attendanceType.name ? item.attendanceType.name : ''} arrow
                        classes={{ tooltip: classes.customTooltip }}
                      >
                        <strong>{item.attendanceType.name}</strong>
                      </StyledTooltip>
                    </div>
                  </TableColumn>
                  <TableColumn width="2.5">
                    <div>
                      <strong
                        className={item.expiredDeadline ? 'expiredDeadline' : ''}
                      >
                        {item.deadline ? item.formattedDeadline : 'Sem data'}
                      </strong>
                    </div>
                  </TableColumn>
                  <TableColumn width="4.5">
                    <div>
                      <StyledTooltip title={item.description ? item.description : ''} arrow
                        classes={{ tooltip: classes.customTooltip }}
                        >
                        <strong>{item.description}</strong>
                      </StyledTooltip>
                    </div>
                  </TableColumn>
                  <TableColumn width="2">
                    <div>
                      <ul>
                        {item.responsibles.map((responsible) => (
                          <li key={responsible.id}>{responsible.user.name}</li>
                        ))}
                      </ul>
                    </div>
                  </TableColumn>
                  <TableColumn width="2.5">
                    <FiInfo
                        title="Ver histórico"
                        onClick={() => {
                          alert(`${item.description}`);
                        }}
                      />

                    <FiEdit3
                      title="Editar"
                      onClick={() => {
                        item.id && handleCallUpdateIntimation(item);
                      }}
                    />

                    <FiXCircle
                      title="Excluir"
                      onClick={() => {
                        item.id && handleDeleteIntimation(item.id, item.user);
                      }}
                    />

                    <FiCheckSquare
                      title="Concluir"
                      onClick={() => {
                        item.id && handleCallFinishIntimation(item);
                      }}
                    />
                  </TableColumn>
                </FlexRow>
              )
            ))}
          </FlexHeaderTable>

        </ContentArea>
      </Content>
    </Container>
  );
};

export default Intimation;
