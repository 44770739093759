import React from 'react';

import {
  FiClipboard,
  FiSettings,
  FiPower,
  FiUser,
  FiHome,
  FiBox,
  FiClock,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import { Container } from './styles';
import logoImg from '../../assets/logo.png';

const LeftMenu: React.FC = () => {
  const { user, signOut } = useAuth();

  return (
    <Container>
      <nav>
        <header>
          {user.avatar_url ? (
            <img src={user.avatar_url} alt={user.name} />
          ) : (
            <img
              src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              alt="avatar"
            />
          )}

          <Link to="/profile">
            <h2>{user.name}</h2>
          </Link>
        </header>
        <ul>
          <li>
            <FiHome />
            <Link to="/dashboard">
              <span>Home</span>
            </Link>
          </li>
          <li>
            <FiUser />
            <Link to="/new-client">
              <span>Cliente</span>
            </Link>
          </li>
          <li>
            <FiClipboard />
            <Link to="/lists">
              <span>Listas</span>
            </Link>
          </li>

          <li>
            <FiSettings />
            <Link to="/configuration">
              <span>Configurações</span>
            </Link>
          </li>
          <li>
            <FiBox />
            <Link to="/document-receipt">
              <span>Utilitários</span>
            </Link>
          </li>
          <li>
            <FiClock />
            <Link to="/process-attendance-list">
              <span>Histórico</span>
            </Link>
          </li>
          {/* <li>&nbsp;</li> */}
          <li>
            <button type="button" onClick={signOut}>
              <FiPower />
              <span>Sair</span>
            </button>
          </li>
        </ul>
        <footer>
          <img src={logoImg} alt="logo" />
        </footer>
      </nav>
    </Container>
  );
};

export default LeftMenu;
