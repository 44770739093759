import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiInfo, FiPlusSquare } from 'react-icons/fi';

import {
  Container,
  Content,
  ContentArea,
  Section,
  Avatar,
  StyledTooltip,
  useStyles,
  FlexHeaderTable,
  TableColumn,
  FlexRow,
} from './styles';

import api from '../../../services/api';

import UtilMenu from '../../../components/UtilMenu';

interface AttendanceType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
}

interface ProcessAttendance {
  id: string | undefined;
  client_id: string | undefined;
  attendance_type_id: string | undefined;
  client_process_id: string | undefined;
  description: string | undefined;
  attendance_date: Date;
  attendanceType: AttendanceType | undefined;
  dateFormatted: string | undefined;
  user_id: string | undefined;
  user: User;
  avatar_url: string;
  checkUser: User;
  updateUser: User;
  avatar_check_user: string;
  check_user_id: string | undefined;
  avatar_update_user: string;
  clientProcess: Process | undefined;
}

interface Process {
  id: string;
  client_id: string;
  competence_id: string;
  folder: string;
  process_number: string;
  process_type_id: string;
  client: Client;
}

interface Client {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  active: string;
  city: City;
}

interface City {
  id: string;
  name: string;
  uf: string;
}


const ProcessAttendanceList: React.FC = () => {
  const history = useHistory();
  const [attendances, setAttendances] = useState<ProcessAttendance[]>([]);
  const classes = useStyles();

  const handleCallClientView = useCallback(
    async (client: Client) => {
      await api.get(`/clients/cpf/${client.cpf}`).then((response) => {
        const cl: Client = response.data;

        localStorage.setItem(
          '@Rastreio:selectedClient',
          JSON.stringify(cl),
        );
        history.push('/update-client', { selectedClient: cl, caller: 'process-attendance-list' });
      });
    },
    [history],
  );

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getUTCMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };

  useEffect(() => {
    api
    .get(
      `/process-attendance/`,
    )
    .then((response) => {
      const attendancessFormatted = response.data.map(
        (attendance: ProcessAttendance) => {
          return {
            ...attendance,
            dateFormatted: formatDate(attendance.attendance_date),
            avatar_url: attendance.user ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            avatar_check_user: attendance.checkUser ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.checkUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            avatar_update_user: attendance.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
          };
        },
      );

      setAttendances(attendancessFormatted);
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
  }, []);
  
  return (
    <Container>
      <Content>
        <UtilMenu />
        <ContentArea>
          <h1>Movimentações Recentes</h1>

          <Section>
          <FlexHeaderTable>
            <FlexRow>
              <TableColumn width="2.5" textAlign="center"><b>Usuário</b></TableColumn>
              <TableColumn width="2" textAlign="center"><b>Data</b></TableColumn>
              <TableColumn width="4" textAlign="center"><b>Cliente</b></TableColumn>
              <TableColumn width="3" textAlign="center"><b>Andamento</b></TableColumn>
              <TableColumn width="3" textAlign="center"><b>Histórico</b></TableColumn>
              <TableColumn width="1" textAlign="center">&nbsp;</TableColumn>
            </FlexRow>

          </FlexHeaderTable>
            {attendances.map((attendance) => (
              attendance.clientProcess?.client.active === 'S' && (
                <FlexRow key={attendance.id}>
                  <TableColumn width="2.5">
                    <Avatar>
                      <StyledTooltip title="Criador" arrow classes={{ tooltip: classes.customTooltip }}>
                        {attendance.checkUser ? (
                          <img src={attendance.avatar_check_user} alt={attendance.checkUser.name} title={attendance.checkUser.name} />
                        ) : (
                          <img
                            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                            alt="avatar"
                          />
                        )}
                      </StyledTooltip>
                      &nbsp;
                      <StyledTooltip title="Check" arrow classes={{ tooltip: classes.customTooltip }}>
                        {attendance.user ? (
                          <img src={attendance.avatar_url} alt={attendance.user.name} title={attendance.user.name} />
                        ) : (
                          <img
                            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                            alt="avatar"
                          />
                        )}
                      </StyledTooltip>
                      &nbsp;
                      <StyledTooltip title="Alterador" arrow classes={{ tooltip: classes.customTooltip }}>
                        {attendance.updateUser ? (
                          <img src={attendance.avatar_update_user} alt={attendance.updateUser.name} title={attendance.updateUser.name} />
                        ) : (
                          <img
                            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                            alt="avatar"
                          />
                        )}
                      </StyledTooltip>
                    </Avatar>
                  </TableColumn>

                  <TableColumn width='2'>
                    <strong>
                      <b>{attendance.dateFormatted}</b>
                    </strong>
                  </TableColumn>
                    
                  <TableColumn width='4'>
                    <Link
                        to="/process-attendance-list"
                        title="Clique para dados do cliente"
                        onClick={() => {
                          attendance.clientProcess?.client && handleCallClientView(attendance.clientProcess.client);
                        }}
                      >
                        <StyledTooltip title={attendance.clientProcess?.client.name ? attendance.clientProcess?.client.name : ''} arrow
                          classes={{ tooltip: classes.customTooltip }}
                        >
                          <strong>
                            <b>{attendance.clientProcess?.client.name}</b>
                          </strong>
                        </StyledTooltip>
                        
                    </Link>
                  </TableColumn>

                  <TableColumn width='3'>
                    <StyledTooltip title={attendance.attendanceType?.name ? attendance.attendanceType?.name : ''} arrow
                      classes={{ tooltip: classes.customTooltip }}
                    >
                      <strong>{attendance.attendanceType?.name}</strong>
                    </StyledTooltip>
                    
                  </TableColumn>

                  <TableColumn width='3'>
                    <StyledTooltip title={attendance.description ? attendance.description : ''} arrow
                      classes={{ tooltip: classes.customTooltip }}
                    >
                      <strong>
                        {attendance.description}
                      </strong>
                    </StyledTooltip>
                  </TableColumn>

                  <TableColumn width='1'>
                    <FiInfo
                      title="Ver histórico"
                      onClick={() => {
                        alert(`${attendance.description}`);
                      }}
                    />

                    <Link
                        to={{
                          pathname: '/process-attendance',
                          state: { 
                            process: attendance.clientProcess, 
                            selectedClient: attendance.clientProcess.client },
                        }}
                        title="Andamento do processo"
                      >
                        <FiPlusSquare />
                    </Link>
                  </TableColumn>
                </FlexRow>
              )
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ProcessAttendanceList;
