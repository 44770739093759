/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FiSearch, FiArrowLeft, FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Container,
  Content,
  ContentArea,
  FormContainer,
  BackContainer,
  FormContainerSearch,
} from './styles';

import api from '../../../services/api';

import LeftMenu from '../../../components/LeftMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';


import Modal from 'react-modal';

Modal.setAppElement('#root'); 

// Estilos para o modal
const customStyles = {
  content: {
    backgroundColor: '#d0f0c0', // Verde claro
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '700px',
    padding: '20px',
    borderRadius: '10px',
  },
};

// Estilos para o container da listagem
const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.6rem;
  max-height: 400px; /* Define uma altura máxima para a rolagem */
  overflow-y: auto; /* Adiciona uma barra de rolagem vertical quando necessário */

  .header {
    font-weight: bold;
    font-size: 1.2em;
    text-align: left;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
  }

  .row {
    display: contents; /* Faz a linha inteira clicável */
    cursor: pointer; /* Adiciona um cursor de ponteiro para indicar que é clicável */
    transition: background-color 0.5s; /* Adiciona transição suave para o hover */
  }

  .row:hover {
    background-color: #a8d5ba; /* Cor de fundo mais escura ao passar o mouse */
  }

  .item {
    padding: 0.5rem 0;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
`;

// Estilos para o ícone de fechar
const CloseIcon = styled(FaTimes)`
  position: relative;
  top: 1px;
  left: 10px;
  cursor: pointer;
  font-size: 1.5em;
  color: #333;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative; /* Necessário para posicionar o ícone */
`;

const Title = styled.h2`
  margin: 0;
`;

// Estilos para o campo de busca
const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

interface AttendanceType {
  id: string;
  name: string;
}

interface IntimationType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
}

interface Client {
  id: string;
  name: string;
  active: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
}

interface ClientProcessComplete {
  id: string;
  process_number: string;
  client: Client;
  folder: string;
}

interface Responsible {
  user_id: string;
}

interface IntimationFormData {
  client_process_id: string;
  // intimation_type_id: string;
  description: string;
  deadline: string;
  responsibles: Responsible[];
  attendance_type_id: string;
  user_id: string;
}

const NewIntimation: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  // const [intimationTypes, setIntimationTypes] = useState<IntimationType[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [clientProcess, setClientProcess] = useState<ClientProcess>();
  const [attendanceTypes, setAttendanceTypes] = useState<AttendanceType[]>([]);
  const [allClientProcess, setAllClientProcess] = useState<ClientProcessComplete[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null); // Crie uma referência para o campo de busca

  const selectedUsers: string[] = [];

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setSearchTerm('');
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      api.get('/client-process/all').then((response) => {
        setAllClientProcess(response.data);
      });
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (modalIsOpen) {
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus(); // Coloca o foco no campo de busca ao abrir o modal
        }
      }, 100); // Ajuste o tempo conforme necessário
    }
  }, [modalIsOpen]);

  // Chamado quando clica na pasta do modal de clientes
  const handleItemClick = (pasta: any) => {
    console.log(pasta);
    formRef.current?.setData({ folder: pasta });
    closeModal();
    handleFindByFolder();
  };

  const filteredData = allClientProcess.filter(item =>
    item.client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/attendance-types').then((response) => {
      setAttendanceTypes(response.data);
    });
  }, []);

  const handleCheckboxClick = useCallback(
    (user_id: string) => {
      const exists = selectedUsers.includes(user_id);
      if (!exists) {
        selectedUsers.push(user_id);
      } else {
        const index = selectedUsers.indexOf(user_id);
        if (index > -1) {
          selectedUsers.splice(index, 1);
        }
      }
    },
    [selectedUsers],
  );

  const handleFindByFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    if (folder) {
      api.get(`/client-process/folder/${folder}`).then((response) => {
        if (response.data) {
          setClientProcess(response.data);
        } else {
          setClientProcess(undefined);
          addToast({
            type: 'info',
            title: 'Pasta não encontrada',
            description: 'Não foi possível localizar a pasta informada.',
          });
        }
      });
    }
  }, [addToast]);

  const handleSubmit = useCallback(
    async (data: IntimationFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          folder: Yup.string().required('Pasta obrigatória'),
          deadline: Yup.string().required('Prazo obrigatório'),
          attendance_type_id: Yup.string().required('Tipo é obrigat[orio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.user_id = user.id;

        if (clientProcess) {
          // eslint-disable-next-line no-param-reassign
          data.responsibles = [];
          // eslint-disable-next-line no-param-reassign
          data.client_process_id = clientProcess.id;
          selectedUsers.map((user_id: string) =>
            data.responsibles.push({
              user_id,
            }),
          );

          // console.log(data);

          await api.post('/intimation', data);

          history.push('/intimation');

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Intimação/tarefa criada',
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso carregar o processo do cliente',
            description: 'Digite a pasta e clique na lupa.',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao cadastrar intimação/tarefa.',
        });
      }
    },
    [addToast, clientProcess, history, selectedUsers, user.id],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Cadastro de Prazo</h1>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <ModalHeader>
              <Title>Listagem de Processos</Title>
              <CloseIcon onClick={closeModal} />
            </ModalHeader>

            <SearchInput
              ref={searchInputRef}
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />

            <ListContainer>
              <div className="header">Cliente</div>
              <div className="header">Processo</div>
              <div className="header">Pasta</div>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  item.client.active === 'S' && (
                    <div key={index} className="row" onClick={() => handleItemClick(item.folder)}>
                      <div className="item">{item.client.name}</div>
                      <div className="item">{item.process_number}</div>
                      <div className="item">{item.folder}</div>
                    </div>
                  )
                ))
              ) : (
                allClientProcess.map((item, index) => (
                  item.client.active === 'S' && (
                    <div key={index} className="row" onClick={() => handleItemClick(item.folder)}>
                      <div className="item">{item.client.name}</div>
                      <div className="item">{item.process_number}</div>
                      <div className="item">{item.folder}</div>
                    </div>
                  )
                ))
              )}
            </ListContainer>
            {/* <button onClick={closeModal}>Fechar</button> */}
          </Modal>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <BackContainer>
              <Link to="/intimation">
                <FiArrowLeft
                  style={{ marginTop: 15 }}
                  size={30}
                  title="Voltar"
                />
              </Link>
            </BackContainer>
            <FormContainerSearch>
              <InputWithLabel name="folder" label="Pasta" type="text" />
              <Button
                type="button"
                title="Dados do cliente"
                onClick={handleFindByFolder}
              >
                <FiSearch size={30} />
              </Button>
              <Button
                type="button"
                title="Clientes"
                onClick={openModal}
              >
                <FiAlignJustify size={30} />
              </Button>
              <label>
                {clientProcess && clientProcess?.client.name}{' '}
                {clientProcess && ' - Processo: '}
                {clientProcess && clientProcess.process_number}
              </label>
            </FormContainerSearch>

            <FormContainer>
              <div>
                <Select
                  name="attendance_type_id"
                  label="Tipo de andamento"
                  initialLabel="Selecione o tipo de andamento"
                  options={attendanceTypes.map((attendanceType) => ({
                    value: attendanceType.id,
                    label: attendanceType.name,
                  }))}
                />
              </div>

              <div>
                <InputWithLabel
                  name="deadline"
                  label="Prazo"
                  placeholder="Prazo"
                  type="date"
                />
              </div>
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Histórico"
                rows={3}
                cols={100}
              />
            </FormContainer>

            <FormContainer>
              <h2>Responsáveis</h2>
            </FormContainer>

            <FormContainer>
              <ul>
                {users.map((user) => (
                  <li key={user.id}>
                    {/* <input type="checkbox" defaultChecked={this.state.chkbox} onChange={this.handleChangeChk} /> */}
                    <input
                      className="checkbox"
                      type="checkbox"
                      onChange={() => {
                        user && handleCheckboxClick(user.id);
                      }}
                    />
                    <span>{user.name}</span>
                  </li>
                ))}
              </ul>
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default NewIntimation;
