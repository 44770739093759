import React, { useRef, useCallback, useState, useEffect } from 'react';

import { FiFolder, FiFileText, FiSearch, FiEdit3 } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { format, isAfter, isBefore } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  ContentArea,
  Section,
  GridContainer,
  ListsContainer,
  BigButtonContainer,
} from './styles';

import api from '../../services/api';

import LeftMenu from '../../components/LeftMenu';
import Input from '../../components/Input';
import Button from '../../components/Button';

interface Client {
  id?: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
}

interface DashboardInterface {
  expiredAdminImplantations: number;
  expiredContactClient: number;
  expiredJudicialImplantations: number;
  expiredMyinss: number;
}

const Dashboard: React.FC = () => {
  const { user, signOut } = useAuth();

  const [selectedClient, setSelectedClient] = useState<Client | null>();
  const [clientNames, setClientNames] = useState<Client[]>([]);
  const [
    expiredJudicialImplantations,
    setExpiredJudicialImplantations,
  ] = useState(0);
  const [expiredContactClients, setExpiredContactClients] = useState(0);
  const [expiredMyinss, setExpiredMyinss] = useState(0);
  const [expiredAdminImplantations, setExpiredAdminImplantations] = useState(0);

  const history = useHistory();
  const { addToast } = useToast();

  const date = new Date();

  let month = `${date.getMonth() + 1}`;
  let day = `${date.getUTCDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  const dd = `${year}-${month}-${day}`;
  // console.log('DD', dd);

  const week_day = format(new Date(), 'iiii', {
    locale: ptBR,
  });
  const present_time = format(new Date(), "''HH':'mm", {
    locale: ptBR,
  });

  const url = `user-restrictions/week_day/${user.id},${
    week_day[0].toUpperCase() + week_day.substr(1)
  }`;

  // console.log(url);

  useEffect(() => {
    api.get(url).then((response) => {
      if (response.data) {
        const startDate = new Date(`${dd}T${response.data.start}`);
        const endDate = new Date(`${dd}T${response.data.finish}`);
        const nowDate = new Date(`${dd}T${present_time.substr(1)}`);

        if (isAfter(nowDate, startDate) && isBefore(nowDate, endDate)) {
          console.log('Dentro do horário permitido');
        } else {
          alert('Horário de utilização não permitido');
          signOut();
        }
      }
    });
  }, [dd, present_time, signOut, url]);

  // console.log('Data', test_date);

  // console.log('Dia da semana', week_day[0].toUpperCase() + week_day.substr(1));
  // console.log('Hora atual', present_time.substr(1));

  useEffect(() => {
    api.get('/clients').then((response) => {
      setClientNames(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/cities').then((response) => {
      localStorage.setItem(
        '@Rastreio:cities',
        JSON.stringify(response.data),
      );
    });
  }, []);

  useEffect(() => {
    api.get('/competences').then((response) => {
      localStorage.setItem(
        '@Rastreio:competences',
        JSON.stringify(response.data),
      );
    });
  }, []);

  useEffect(() => {
    api.get('/process-types').then((response) => {
      localStorage.setItem(
        '@Rastreio:process_types',
        JSON.stringify(response.data),
      );
    });
  }, []);

  useEffect(() => {
    api.get('/dashboard').then((response) => {
      const dashboard: DashboardInterface = response.data;
      setExpiredAdminImplantations(dashboard.expiredAdminImplantations);
      setExpiredMyinss(dashboard.expiredMyinss);
      setExpiredContactClients(dashboard.expiredContactClient);
      setExpiredJudicialImplantations(dashboard.expiredJudicialImplantations);
    });
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleCallClientView = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    const processNumber = formRef.current?.getFieldValue('process_number');

    if (selectedClient && !folder && !processNumber) {
      console.log('selectedClient****', selectedClient);
      localStorage.setItem(
        '@Rastreio:selectedClient',
        JSON.stringify(selectedClient),
      );

      history.push('/update-client', { selectedClient });
    } else if (!selectedClient && !processNumber && folder) {
      if (folder) {
        api.get(`/client-process/folder/${folder}`).then((response) => {
          if (response.data) {
            const { client } = response.data;
            setSelectedClient(client);
            if (client) {
              console.log('Client', client);
              localStorage.setItem(
                '@Rastreio:selectedClient',
                JSON.stringify(selectedClient),
              );

              history.push('/update-client', { selectedClient: client });
            }
          } else {
            addToast({
              type: 'info',
              title: 'Pasta não encontrada',
              description: 'Não foi possível localizar a pasta informada.',
            });
          }
        });
      }
    } else if (!selectedClient && !folder && processNumber) {
      api
        .get(`/client-process/process-number/${processNumber}`)
        .then((response) => {
          if (response.data) {
            const { client } = response.data;
            setSelectedClient(client);
            if (client) {
              console.log('Client', client);
              localStorage.setItem(
                '@Rastreio:selectedClient',
                JSON.stringify(selectedClient),
              );

              history.push('/update-client', { selectedClient: client });
            }
          } else {
            addToast({
              type: 'info',
              title: 'Processo não encontrado',
              description: 'Não foi possível localizar o processo informado.',
            });
          }
        });
    } else {
      addToast({
        type: 'info',
        title: 'Cliente não selecionado',
        description: 'Selecione um cliente.',
      });
    }
  }, [addToast, history, selectedClient]);

  const handleAttendance = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    const processNumber = formRef.current?.getFieldValue('process_number');

    if (selectedClient) {
      api
        .get(`/client-process/client/${selectedClient.id}`)
        .then((response) => {
          console.log('resultado', response.data);
          if (response.data.length > 1) {
            history.push('/client-process', {
              selectedClient,
            });
          } else {
            history.push('/process-attendance', {
              process: response.data[0],
              selectedClient,
            });
          }
        });
    } else {
      if (folder) {
        api.get(`/client-process/folder/${folder}`).then((response) => {
          if (response.data) {
            localStorage.setItem(
              '@Rastreio:selectedClient',
              JSON.stringify(response.data.client),
            );
            history.push('/process-attendance', {
              process: response.data,
              selectedClient: response.data.client,
            });
          } else {
            addToast({
              type: 'info',
              title: 'Pasta não encontrada',
              description: 'Não foi possível localizar a pasta informada.',
            });
          }
        });
      }
      if (processNumber) {
        api
          .get(`/client-process/process-number/${processNumber}`)
          .then((response) => {
            if (response.data) {
              history.push('/process-attendance', {
                process: response.data,
                selectedClient: response.data.client,
              });
            } else {
              addToast({
                type: 'info',
                title: 'Processo não encontrado',
                description: 'Não foi possível localizar o processo informado.',
              });
            }
          });
      }
    }
  }, [addToast, history, selectedClient]);

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h2>Pesquisar Cliente</h2>
          <Section>
            <Form
              ref={formRef}
              onSubmit={() => {
                console.log('nada');
              }}
            >
              <GridContainer>
                <div>
                  <Autocomplete
                    id="client_name"
                    size="medium"
                    style={{ width: 250 }}
                    options={clientNames}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setSelectedClient(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ backgroundColor: '#fff', padding: '0' }}
                        {...params}
                        placeholder="Digite ou selecione o Cliente..."
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div>
                  <Input
                    name="folder"
                    icon={FiFolder}
                    placeholder="Pasta"
                    onFocus={() => {
                      formRef.current?.setData({ process_number: '' });
                    }}
                  />
                </div>
                <div>
                  <Input
                    name="process_number"
                    icon={FiFileText}
                    placeholder="Número do Processo"
                    onFocus={() => {
                      formRef.current?.setData({ folder: '' });
                    }}
                  />
                </div>
                <div>
                  <Button
                    style={{ background: '#00b35b' }}
                    type="button"
                    title="Dados do cliente"
                    onClick={handleCallClientView}
                  >
                    <FiSearch size={30} />
                  </Button>
                  <Button
                    style={{
                      marginLeft: -5,
                      marginRight: 10,
                      background: '#da6841',
                    }}
                    type="button"
                    title="Andamento"
                    onClick={handleAttendance}
                  >
                    <FiEdit3 />
                  </Button>
                </div>
              </GridContainer>
              <BigButtonContainer>
                <Link to="/new-client">
                  <Button
                    // style={{
                    //   marginLeft: 35,
                    //   width: 935,
                    //   marginTop: -12,
                    //   marginBottom: 30,
                    // }}
                    type="button"
                  >
                    Novo Cliente
                  </Button>
                </Link>
              </BigButtonContainer>

              <h2>Notificações</h2>
              <ListsContainer>
              <Link to="/intimation">
                  <div className="myinss">
                    <h3>Prazos</h3>
                  </div>
                  <div className="notification-label">
                    <span>{expiredMyinss} item(s) vencidos</span>
                  </div>
                </Link>

                <Link to="/contact-client-list">
                  <div className="contact-client">
                    <h3>Contactar Clientes</h3>
                  </div>
                  <div className="notification-label">
                    <span>{expiredContactClients} item(s) vencidos</span>
                  </div>
                </Link>

                <Link to="/judicial-implantation-list">
                  <div className="judicial-implantation">
                    <h3>Implantação Judicial</h3>
                  </div>
                  <div className="notification-label">
                    <span>{expiredJudicialImplantations} item(s) vencidos</span>
                  </div>
                </Link>

                <Link to="/admin-implantation-list">
                  <div className="admin-implantation">
                    <h3>Implantação Administrativa</h3>
                  </div>
                  <div className="notification-label">
                    <span>{expiredAdminImplantations} item(s) vencidos</span>
                  </div>
                </Link>
              </ListsContainer>
              {/* <Link to="/forgot-password">Esqueci minha senha</Link> */}
            </Form>
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default Dashboard;
