import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const Content = styled.main`
  display: flex;
  flex: 1;
`;

export const ContentArea = styled.main`
  flex: 1;

  height: 100vh;

  margin-left: 250px;
  margin-right: 50px;

  h1 {
    margin-top: 40px;
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`;

export const BackContainer = styled.div`
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;
`;

export const FormContainerSearch = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: start; /* Alinha o grid à esquerda */
  align-items: center;

  label {
    margin-top: 30px;
  }

  button {
    margin-top: 25px;
    margin-right: 3px;
    margin-left: 5px;
    width: 60px;
    text-align: center;
  }

  

`;

export const FormContainer = styled.div`
  margin-top: 30px;
  justify-content: start;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  //grid-template-columns: 1fr 3fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  // .process-client {
  //   margin-left: -200px;
  // }

  /* Adicionando estilo para inputs e labels */
  input[type="text"], label {
    grid-column: span 4; /* Ocupa toda a linha, ajuste conforme necessário */
    margin-bottom: 0.5rem;
  }

  input[type="text"] {
    width: 100%; /* Garante que o input ocupe toda a largura */
  }

  button {
    margin-top: 25px;
    margin-right: 0;
    width: 60px;
    text-align: center;
  }

  ul {
    list-style: none;
    /* padding: 0.5em 0; */
    margin-top: -20px;

    li {
      display: flex;
      align-items: center;

      padding: 1em 1em 0.5em 0;
      font-size: 0.95em;
      font-weight: regular;
      background-repeat: no-repeat;
      background-position: left 15px center;
      background-size: auto 20px;
      transition: all 0.15s linear;
      /* cursor: pointer; */

      span {
        color: #000;
        /* color: #fff; */
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
        margin-left: 20px;
      }

      &:focus {
        outline: none;
      }

      .checkbox {
        position: absolute;
        cursor: pointer;
        transform: scale(1.5);
      }
    }
  }

`;

export const FormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-top: 16px;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    border-radius: 10px;

    button {
      margin: 0;
      width: 60px;
      text-align: center;
    }
  }
`;
