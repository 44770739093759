/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';

import { FiXCircle, FiEdit3, FiArrowLeft, FiInfo, } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useAuth } from '../../hooks/auth';

// import { useHistory } from 'react-router-dom';
// import { parseISO, format } from 'date-fns';
// import ptBR from 'date-fns/locale/pt-BR';

import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  ContentArea,
  FormContainer,
  Section,
  FormContainer2,
  FormContainer3,
  Attendance,
  FormContainerHeader,
  Avatar,
  StyledTooltip,
  useStyles,
} from './styles';

import api from '../../services/api';

import LeftMenu from '../../components/LeftMenu';
import Input from '../../components/Input';
import InputWithLabel from '../../components/InputWithLabel';
import Select from '../../components/Select';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';

interface AttendanceType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
}

interface ProcessAttendanceFormData {
  id: string | undefined;
  client_id: string | undefined;
  attendance_type_id: string | undefined;
  client_process_id: string | undefined;
  description: string | undefined;
  attendance_date: Date;
  attendanceType: AttendanceType | undefined;
  dateFormatted: string | undefined;
  user_id: string | undefined;
  user: User;
  avatar_url: string;
  checkUser: User;
  updateUser: User;
  avatar_check_user: string;
  check_user_id: string | undefined;
  avatar_update_user: string;
}

interface Client {
  id: string;
  name: string;
}

interface ProcessType {
  id: string;
  name: string;
}

interface Competence {
  id: string;
  name: string;
}

interface Process {
  id: string;
  client_id: string;
  competence: Competence;
  competence_id: string;
  folder: string;
  processType: ProcessType;
  process_number: string;
  process_type_id: string;
}

interface Location {
  selectedClient: Client;
  process: Process;
}

const ProcessAttendance: React.FC = () => {
  // const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const { user } = useAuth();
  const classes = useStyles();
  
  const locationState: Location = location.state as Location;

  const { selectedClient } = locationState;
  const clientProcess: Process = locationState.process;

  // console.log('locationState', locationState);

  // console.log('process', clientProcess);

  const [attendanceTypes, setAttendanceTypes] = useState<AttendanceType[]>([]);
  const [attendances, setAttendances] = useState<ProcessAttendanceFormData[]>(
    [],
  );

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getUTCMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };

  useEffect(() => {
    if (clientProcess) {
      formRef.current?.setData({ folder: clientProcess.folder });
      formRef.current?.setData({
        process_type: clientProcess.processType?.name,
      });
      formRef.current?.setData({
        competence: clientProcess.competence?.name,
      });
      formRef.current?.setData({
        process_number: clientProcess.process_number,
      });
    }
  }, [clientProcess]);

  useEffect(() => {
    formRef.current?.setData({
      attendance_date: new Date().toISOString().slice(0,10)
    });
    
    api.get('/attendance-types').then((response) => {
      setAttendanceTypes(response.data);
    });
  }, []);

  // console.log('client_id', selectedClient.id);
  // console.log('client_process_id', clientProcess.id);

  useEffect(() => {
    if (clientProcess && selectedClient) {
      api
      .get(
        `/process-attendance/client/${selectedClient.id}/${clientProcess.id}`,
      )
      .then((response) => {
        const attendancessFormatted = response.data.map(
          (attendance: ProcessAttendanceFormData) => {
            return {
              ...attendance,
              dateFormatted: formatDate(attendance.attendance_date),
              avatar_url: attendance.user ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
              avatar_check_user: attendance.checkUser ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.checkUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
              avatar_update_user: attendance.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            };
          },
        );

        setAttendances(attendancessFormatted);
      }).catch((error) => {
        console.error('Error fetching data:', error);
      });;
    }
    
  }, [clientProcess, selectedClient]);

  const handleSubmit = useCallback(
    async (data: ProcessAttendanceFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          attendance_date: Yup.string().required(
            'Data do andamento obrigatória',
          ),
          attendance_type_id: Yup.string().required(
            'Tipo do andamento obrigatório',
          ),
          // description: Yup.string().required(
          //   'Histórico do andamento obrigatório',
          // ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const attendanceType = attendanceTypes.find(
          (att) => att.id === data.attendance_type_id,
        );

        if (!data.id) {
          const processAttendance = await api.post('/process-attendance', {
            client_id: selectedClient?.id,
            attendance_type_id: data.attendance_type_id,
            client_process_id: clientProcess?.id,
            description: data.description,
            attendance_date: data.attendance_date,
            check_user_id: user.id,
          });

          if (attendanceType?.name === 'Implantação Judicial') {
            await api.post('/judicial-implantation-list', {
              client_id: selectedClient?.id,
              client_process_id: clientProcess?.id,
              process_attendance_id: processAttendance.data.id,
              deadline: data.attendance_date,
              description: data.description,
              done: false,
              user_id: data.user_id,
            });
          }

          if (attendanceType?.name === 'Contato com o Cliente') {
            await api.post('/contact-client-list', {
              client_id: selectedClient?.id,
              process_attendance_id: processAttendance.data.id,
              contact_date: data.attendance_date,
              client_process_id: clientProcess?.id,
              description: data.description,
              done: false,
              user_id: data.user_id,
            });
          }

          if (attendanceType?.name === 'Meu INSS') {
            await api.post('/myinss-list', {
              client_id: selectedClient?.id,
              client_process_id: clientProcess?.id,
              process_attendance_id: processAttendance.data.id,
              der: data.attendance_date,
              done: false,
              user_id: data.user_id,
            });
          }

          if (attendanceType?.name === 'Implantação Administrativa') {
            await api.post('/admin-implantation-list', {
              client_id: selectedClient?.id,
              client_process_id: clientProcess?.id,
              process_attendance_id: processAttendance.data.id,
              deadline: data.attendance_date,
              description: data.description,
              done: false,
              user_id: data.user_id,
            });
          }
        } else {
          // Para debug....
          // console.log('Editando....');
          // console.log('data.id', data.id);
          // console.log('data.attendance_type_id', data.attendance_type_id);
          // console.log('client_process_id', clientProcess?.id);
          // console.log('description', data.description);
          // console.log('attendance_date', data.attendance_date);
          // alert(data.user_id);
          // alert(data.check_user_id);

          
          await api.delete(`/process-attendance/${data.id}`);

          await api.post('/process-attendance', {
            client_id: selectedClient?.id,
            attendance_type_id: data.attendance_type_id,
            client_process_id: clientProcess?.id,
            description: data.description,
            attendance_date: data.attendance_date,
            ...(data.user_id && { user_id: data.user_id }),
            ...(data.check_user_id && { check_user_id: data.check_user_id }),
            update_user_id: user.id,
          });

        }

        await api
          .get(
            `/process-attendance/client/${selectedClient.id}/${clientProcess.id}`,
          )
          .then((response) => {
            const attendancessFormatted = response.data.map(
              (attendance: ProcessAttendanceFormData) => {
                return {
                  ...attendance,
                  dateFormatted: formatDate(attendance.attendance_date),
                  avatar_url: attendance.user ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
                  avatar_check_user: attendance.checkUser ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.checkUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
                  avatar_update_user: attendance.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${attendance.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
                };
              },
            );

            setAttendances(attendancessFormatted);
          });

        formRef.current?.reset();
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [attendanceTypes, clientProcess, selectedClient, user.id],
  );

  const handleDeleteAttendance = useCallback(
    async (attendance: ProcessAttendanceFormData) => {
      // eslint-disable-next-line no-restricted-globals
      const c = confirm('Deseja excluir?');
      if (c === true) {
        console.log('Attendance id', attendance.id);
        if (attendance.checkUser) {
          if (user.id !== attendance.checkUser.id) {
            alert('Você não pode excluir um lançamento feito por outro usuário');
          }
          else {
            await api
            .delete(`/process-attendance/${attendance.id}`)
            .then((response) => {
              setAttendances((attd) =>
                attd.filter((_attd) => _attd.id !== attendance.id),
              );
            });

            if (attendance.attendanceType?.name === 'Implantação Judicial') {
              await api.delete(
                `/judicial-implantation-list/client_process/${attendance.client_process_id}`,
              );
            }
          }
        }
        else {
          await api
          .delete(`/process-attendance/${attendance.id}`)
          .then((response) => {
            setAttendances((attd) =>
              attd.filter((_attd) => _attd.id !== attendance.id),
            );
          });

          if (attendance.attendanceType?.name === 'Implantação Judicial') {
            await api.delete(
              `/judicial-implantation-list/client_process/${attendance.client_process_id}`,
            );
          }
        }
        
      }



      /*
      const c = confirm('Deseja excluir?');
      if (c === true) {
        console.log('Attendance id', attendance.id);
        await api
          .delete(`/process-attendance/${attendance.id}`)
          .then((response) => {
            setAttendances((attd) =>
              attd.filter((_attd) => _attd.id !== attendance.id),
            );
          });

        if (attendance.attendanceType?.name === 'Implantação Judicial') {
          await api.delete(
            `/judicial-implantation-list/client_process/${attendance.client_process_id}`,
          );
        }
      }
      */
    },
    [user.id],
  );

  const handleLoadValuesToFields = useCallback(
    (processAttendance: ProcessAttendanceFormData) => {
      window.scrollTo(0, 0);
      const attendanceDate: string = processAttendance.attendance_date
        .toString()
        .substring(0, 10);

      formRef.current?.setData({ id: processAttendance.id });
      formRef.current?.setData({ user_id: processAttendance.user_id });
      formRef.current?.setData({ check_user_id: processAttendance.check_user_id });

      formRef.current?.setData({
        attendance_date: attendanceDate,
      });
      formRef.current?.setData({
        attendance_type_id: processAttendance.attendance_type_id,
      });
      formRef.current?.setData({ description: processAttendance.description });
    },
    [],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h2>
            <b>{selectedClient.name} - Pasta: {clientProcess.folder}</b>
          </h2>
          <h3>Andamentos</h3>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer2>
              <div style={{ display: 'none' }}>
                <Input name="id" hidden />
              </div>
              <div style={{ display: 'none' }}>
                <Input name="user_id" hidden/>
              </div>
              <div style={{ display: 'none' }}>
                <Input name="check_user_id" hidden/>
              </div>
              <InputWithLabel name="attendance_date" label="Data" type="date" />

              <Select
                name="attendance_type_id"
                label="Tipo de acompanhamento"
                initialLabel="Selecione o tipo de acompanhamento"
                options={attendanceTypes.map((attendanceType) => ({
                  value: attendanceType.id,
                  label: attendanceType.name,
                }))}
              />
            </FormContainer2>

            <FormContainer3>
              <TextArea
                name="description"
                label="Histórico"
                rows={3}
                cols={100}
              />
            </FormContainer3>

            <FormContainer>
              <Button type="submit">Salvar</Button>
            </FormContainer>
            <FormContainer>
              <Link
                to={{
                  pathname: '/update-client',
                  state: { selectedClient },
                }}
              >
                <FiArrowLeft
                  style={{ marginTop: 15 }}
                  size={30}
                  title="Cadastro do Cliente"
                />
              </Link>
            </FormContainer>

            <FormContainerHeader>
              <div>
                <strong>&nbsp;</strong> 
              </div>
              <div>
                <strong>Data</strong>
              </div>
              <div>
                <strong>Tipo</strong>
              </div>
              <div>
                <strong>Histórico</strong>
              </div>
            </FormContainerHeader>

            <Section>
              {attendances.map((attendance) => (
                <Attendance key={attendance.id}>
                  <Avatar>
                    <StyledTooltip title="Criador" arrow classes={{ tooltip: classes.customTooltip }}>
                      {attendance.checkUser ? (
                        <img src={attendance.avatar_check_user} alt={attendance.checkUser.name} title={attendance.checkUser.name} />
                      ) : (
                        <img
                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          alt="avatar"
                        />
                      )}
                    </StyledTooltip>
                    &nbsp;
                    <StyledTooltip title="Check" arrow classes={{ tooltip: classes.customTooltip }}>
                      {attendance.user ? (
                        <img src={attendance.avatar_url} alt={attendance.user.name} title={attendance.user.name} />
                      ) : (
                        <img
                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          alt="avatar"
                        />
                      )}
                    </StyledTooltip>
                    &nbsp;
                    <StyledTooltip title="Alterador" arrow classes={{ tooltip: classes.customTooltip }}>
                      {attendance.updateUser ? (
                        <img src={attendance.avatar_update_user} alt={attendance.updateUser.name} title={attendance.updateUser.name} />
                      ) : (
                        <img
                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          alt="avatar"
                        />
                      )}
                    </StyledTooltip>
                  </Avatar>
                  
                  <div>
                    <strong>
                      <b>{attendance.dateFormatted}</b>
                    </strong>
                  </div>
                  <div>
                    <strong>{attendance.attendanceType?.name}</strong>
                  </div>
                  <div>
                    <StyledTooltip title={attendance.description ? attendance.description : ''} arrow
                      classes={{ tooltip: classes.customTooltip }}
                    >
                      <strong>
                        {attendance.description}
                      </strong>
                    </StyledTooltip>
                  </div>
                  <div>
                    <FiInfo
                      title="Ver histórico"
                      onClick={() => {
                        alert(`${attendance.description}`);
                      }}
                    />
                    <FiEdit3
                      title="Editar"
                      onClick={() => {
                        attendance.id && handleLoadValuesToFields(attendance);
                      }}
                    />
                    <FiXCircle
                      onClick={() => {
                        attendance.id && handleDeleteAttendance(attendance);
                      }}
                      title="Excluir"
                    />
                  </div>
                </Attendance>
              ))}
            </Section>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ProcessAttendance;
