/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer, ObservationContainer } from './styles';

import api from '../../services/api';

import LeftMenu from '../../components/LeftMenu';
import InputWithLabel from '../../components/InputWithLabel';
import InputCpf from '../../components/InputCpf';
import InputCep from '../../components/InputCep';
import Select from '../../components/Select';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';

interface ClientFormData {
  user_id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  // password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  process_type: string;
  competence: string;
  process_number: string;
  folder: string;
  observation: string;
}

interface City {
  id: string;
  name: string;
}

interface Competence {
  id: string;
  name: string;
}

interface ProcessType {
  id: string;
  name: string;
}

interface Client {
  data: {
    id: string;
    name: string;
    cpf: string;
  };
}

interface Client2 {
  id: string;
  name: string;
  cpf: string;
  // password: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  folder: string;
  client: Client2;
}


const NewClient: React.FC = () => {
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const user = localStorage.getItem('@Rastreio:user');
  localStorage.removeItem('@Rastreio:selectedClient');

  const [cities, setCities] = useState<City[]>([]);
  const [competences, setCompetences] = useState<Competence[]>([]);
  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);
  const [selectedBirthDate, setSelectedBirthDate] = useState('');
  const [age, setAge] = useState(0);
  
  useEffect(() => {
    // api.get('/cities').then((response) => {
    //   setCities(response.data);
    // });
    fetch('./cities.json')
      .then((response) => response.json())
      .then((data) => {
        setCities(data);
      })
      .catch((error) => {
        console.error('Erro ao carregar o arquivo JSON:', error);
      });
  }, []);

  useEffect(() => {
    api.get('/competences').then((response) => {
      setCompetences(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/process-types').then((response) => {
      setProcessTypes(response.data);
    });
  }, []);

  const calculateAge = useCallback((paramAge) => {
    const today = new Date();
    const birthDate = new Date(paramAge);
    let tmpage = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      tmpage--;
    }

    setAge(tmpage);
  }, []);

  useEffect(() => {
    calculateAge(selectedBirthDate);
  }, [calculateAge, selectedBirthDate]);

  const handleSubmit = useCallback(
    async (data: ClientFormData) => {
      try {
        if (user) {
          // alert('Cadastro realizado');

          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            cpf: Yup.string().required('CPF obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          // eslint-disable-next-line no-param-reassign
          data.user_id = JSON.parse(user).id;

          if (data.folder) {
            const schema2 = Yup.object().shape({
              folder: Yup.string().required('Pasta obrigatória'),
              process_type: Yup.string().required(
                'Tipo de processo obrigatório',
              ),
              competence: Yup.string().required('Competência obrigatória'),
            });

            await schema2.validate(data, {
              abortEarly: false,
            });

            const schema3 = Yup.object().shape({
              folder: Yup.string().required('Pasta já utilizada'),
            });

            await api.get(`/client-process/folder/${data.folder}`).then(async (response) => {
              if (response.data) {
                const retorno: ClientProcess  = response.data as ClientProcess;

                data.folder = '';
                
                addToast({
                  type: 'error',
                  title: `Pasta já utilizada`,
                  description: `Esta pasta já está em uso pelo cliente ${retorno.client.name}`,
                });

                await schema3.validate(data, {
                  abortEarly: false,
                });
              }
            });
          }

          const schemaExistsCpf = Yup.object().shape({
            cpf: Yup.string().required('CPF já utilizado'),
          });

          await api.get(`/clients/cpf/${data.cpf}`).then(async (response) => {
            const clientFound: ClientFormData = response.data as ClientFormData
    
            if (clientFound) {
              data.cpf = '';
              alert(`Este CPF já está em uso pelo cliente ${clientFound.name}`);

              await schemaExistsCpf.validate(data, {
                abortEarly: false,
              });
            }
          });
          
          const client: Client = await api.post('/clients', data);

          
          if (data.folder) {
            await api.post('/client-process', {
              client_id: client.data.id,
              folder: data.folder,
              process_type_id: data.process_type,
              competence_id:
                data.competence.length > 0 ? data.competence : null,
              process_number: data.process_number,
            });

          }
          

          // history.push('/dashboard');

          alert('Cadastro realizado');

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Novo cliente criado',
          });

          await api.get(`/clients/cpf/${data.cpf}`).then((response) => {
            const clientCreated: ClientFormData = response.data as ClientFormData;
            
            if (clientCreated) {
              localStorage.setItem(
                '@Rastreio:selectedClient',
                JSON.stringify(clientCreated),
              );
              history.push('/update-client', { selectedClient: clientCreated });
            }
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao cadastrar cliente.',
        });
      }
    },
    [addToast, user, history],
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const toInputUppercase = (e: any) => {
    e.target.value = `${e.target.value}`.toUpperCase();
  };

  // const handleCallWordDocumentView = useCallback(() => {
  //     console.log(newClient);
  //     if (newClient) {
  //       // history.push('/word-document', { client: newClient, city: cities.find((x) => x.id === newClient.city_id),  });
  //       history.push({
  //         pathname: '/word-document',
  //         state: {
  //           client: newClient, 
  //           city: cities.find((x) => x.id === newClient.city_id)
  //         }
  //       })
  //     }
  //   },
  //   [history, newClient]
  // );


  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Cadastro de Cliente</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              {/* <label>&nbsp;</label>
              <label>&nbsp;</label>
              <InputWithLabel name="folder" label="Pasta" /> */}

              <InputWithLabel
                label="Nome"
                name="name"
                onChange={toInputUppercase}
              />
              <InputCpf label="CPF" name="cpf" />

              <InputWithLabel name="folder" label="Pasta" />

              <InputWithLabel name="rg" label="RG" />

              <InputWithLabel name="nit" label="NIT" />
              <div className="birthDiv">
                <InputWithLabel
                  name="birth_date"
                  label="Data de Nascimento"
                  placeholder="Data de Nascimento"
                  onBlur={(event) => setSelectedBirthDate(event.target.value)}
                  type="date"
                />
                <label>Idade: {age || ''}</label>
              </div>
              
              {/* <InputWithLabel name="password" label="Senha" /> */}

              <InputWithLabel name="phones" label="Telefones" />

              <Select
                name="marital_status"
                label="Estado Civil"
                initialLabel="Selecione o Estado Civil"
                options={[
                  { value: 'solteiro(a)', label: 'solteiro(a)' },
                  { value: 'casado(a)', label: 'casado(a)' },
                  { value: 'divorciado(a)', label: 'divorciado(a)' },
                  { value: 'viúvo(a)', label: 'viúvo(a)' },
                  { value: 'união estável', label: 'união estável' },
                  { value: 'separado(a)', label: 'separado(a)' },
                ]}
              />

              <InputWithLabel
                name="email"
                label="E-mail"
              />

              <InputWithLabel
                name="address"
                label="Endereço"
              />

              <Select
                name="city_id"
                label="Cidade"
                initialLabel="Selecione a Cidade"
                options={cities.map((city) => ({
                  value: city.id,
                  label: city.name,
                }))}
              />

              <InputCep name="cep" label="CEP" maxLength={9} />
              <InputWithLabel
                name="profession"
                label="Profissão"
              />

              <Select
                name="process_type"
                label="Tipo do processo"
                initialLabel="Selecione o Tipo do Processo"
                options={processTypes.map((processType) => ({
                  value: processType.id,
                  label: processType.name,
                }))}
              />

              <Select
                name="competence"
                label="Competência"
                initialLabel="Selecione a Competência"
                options={competences.map((competence) => ({
                  value: competence.id,
                  label: competence.name,
                }))}
              />

              <InputWithLabel name="process_number" label="Nº do Processo" />
            </FormContainer>
            <ObservationContainer>
              <TextArea
                name="observation"
                label="Observações"
                rows={20}
                cols={130}
              />
            </ObservationContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default NewClient;
